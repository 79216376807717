import * as React from "react"
import PropTypes from "prop-types"

import "./layout.css"

const Layout = ({ children }) => {
  return (
    <>
      <div class>
        <header class="flex w-full flex-row-reverse border-b pt-1 px-3 sm:px-3">
          <ul class="flex my-2 gap-2 list-discs">
            {[
              ["hem", "/"],
              ["meny", "/menu"],
              ["lunch", "/lunch"],
              ["kontakt", "/find-us"],
              ["öppettider", "/opening-hours"],
            ].map(([title, url]) => (
              <li class="flex-1 my-0">
                <a href={url}>{title}</a>
              </li>
            ))}
          </ul>
        </header>
        <main>{children}</main>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
